<template>
  <div class="all-order bsbb fz28">
    <div class="order-list df fdc">
      <div class="item mb20 p15 bsbb" v-for="(v, i) in orderList" :key="i">
        <div class="df aic jcsb font-size-24 fw7" style="border-bottom: 1px solid #f2f2f2; padding-bottom: 8px">
          <p>全民百惠</p>
          <p>{{ formatDate(v.create_time * 1000) }} 下单</p>
        </div>
        <div class="df aic jcsb text p15 bsbb">
          <div class="df aic font-size-20">
            <img :src="v.order_img" class="mr20" />
            <p style="color: #77d477">写评价,商品好坏就差你的发声了</p>
          </div>
          <!-- {{ formatYMD(this.date) }} -->
          <!-- <van-button type="default" @click="goCommend(v.id)">评价</van-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMyOrderReq } from "@/utils/api.js";
export default {
  data() {
    return {
      orderList: [],
      date: 11111111111111111111111,
    };
  },
  methods: {
    formatDate(value) {//调用时间戳为日期显示
      let date = new Date(value)
      let y = date.getFullYear()  //获取年份
      let m = date.getMonth() + 1  //获取月份
      m = m < 10 ? "0" + m : m  //月份不满10天显示前加0
      let d = date.getDate()  //获取日期
      d = d < 10 ? "0" + d : d  //日期不满10天显示前加0
      let h = date.getHours()                  //小时
      h = h < 10 ? '0' + h : h
      let M = date.getMinutes()               //分
      M = M < 10 ? '0' + M : M
      let s = date.getSeconds()                 //秒
      s = s < 10 ? '0' + s : s
      return y + "-" + m + "-" + d + ' ' + h + ':' + M + ':' + s
      // let ls = date.getMilliseconds()            //毫秒
    },
    // goCommend(n) {
    //   this.$router.push({
    //     name: "evaluateContent",
    //     query: {
    //       id: n,
    //     },
    //   });
    // },
  },
  mounted() {
    getMyOrderReq({ page: 1, page_size: 50 }).then((res) => {
      this.orderList = res.data.list;
    });
  },
};
</script>

<style lang="scss" scoped>
.all-order {
  width: 100%;
  height: 100%;

  .order-list {
    .item {
      width: 684px;
      height: 213px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 1);

      .text {
        img {
          width: 100px;
          height: 100px;
          border-radius: 8px;
        }

        .van-button {
          width: 129px;
          height: 57px;
          border-radius: 15px;
        }
      }
    }
  }
}
</style>